/* You can add global styles to this file, and also import other style files */
@import '~locomotive-scroll/dist/locomotive-scroll.css';
@import '~swiper/swiper-bundle.css';
@import '~include-media/dist/include-media';

html, body { height: 100% }
body {
  margin: 0;
}
html {
  box-sizing: border-box;
}

*, :after, :before {
  box-sizing: inherit;
}
.u-text {
  color: #aaa;
}


@include media('>tablet') {
  .section {
    height:100vh;
  }
}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.c-scrollbar {
  z-index: 10000;
}


@font-face {
  font-family: 'Roobert';
  src: url('/assets/fonts/tocheck/roobert/RoobertPRO.woff2') format('woff2'),
  url('/assets/fonts/tocheck/roobert/RoobertPRO.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "GT Super Display";
  src: local("GT Super Display"), url('./assets/hinted-GTSuperDisplay-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/tocheck/raleway/Raleway-Thin.woff2') format('woff2'),
  url('./assets/fonts/tocheck/raleway/Raleway-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'mulish';
  src: url('./assets/fonts/tocheck/mulish/subset-Mulish-ExtraLight.woff2') format('woff2'),
  url('./assets/fonts/tocheck/mulish/subset-Mulish-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'ptserif';
  src: url('./assets/fonts/tocheck/ptserif/ptserif-webfont.woff2') format('woff2'),
  url('./assets/fonts/tocheck/ptserif/ptserif-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceCodePro';
  src: url('./assets/fonts/tocheck/scp/sourcecodepro-webfont.woff2') format('woff2'),
  url('./assets/fonts/tocheck/scp/sourcecodepro-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'YanoneKaffeesatz';
  src: url('./assets/fonts/tocheck/ysnonkaffesatz/yanonekaffeesatz-webfont.woff2') format('woff2'),
  url('./assets/fonts/tocheck/ysnonkaffesatz/yanonekaffeesatz-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'comfortaa';
  src: url('./assets/fonts/tocheck/comfortaa/comfortaa-webfont.woff2') format('woff2'),
  url('./assets/fonts/tocheck/comfortaa/comfortaa-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "mincho";
  src: url('./assets/fonts/tocheck/mincho.ttf') format('ttf');
}

@font-face {
  font-family: 'mincho';
  src: url('./assets/fonts/tocheck/mincho/subset-BIZUDPMincho-Regular.woff2') format('woff2'),
  url('./assets/fonts/tocheck/mincho/subset-BIZUDPMincho-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair';
  src: url('./assets/fonts/tocheck/playfair/subset-PlayfairDisplay-Regular.woff2') format('woff2'),
  url('./assets/fonts/tocheck/playfair/subset-PlayfairDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "GT Super Display Light";
  src: url("./assets/gtlight.eot"); /* IE9*/
  src: url("./assets/gtlight.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./assets/gtlight.eot") format("woff2"), /* chrome、firefox */
  url("./assets/gtlight.woff") format("woff"), /* chrome、firefox */
  url("./assets/gtlight.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("./assets/gtlight.svg#GT Super Display Light") format("svg"); /* iOS 4.1- */
}

@function vh($quantity) {
  @return calc(1vh * #{$quantity});
}

